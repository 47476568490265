var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.formTitle))]),_c('v-divider')],1)],1),_c('v-form',{ref:"roleDetailForm",attrs:{"id":"iiotRoleDetailForm"},on:{"submit":function($event){$event.preventDefault();return _vm.saveRole.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[(_vm.role.type === 'ldap')?_c('v-text-field',{ref:"roleNameInput",attrs:{"id":"iiotRoleDetailsFormName","disabled":!((_vm.roleId && _vm.canAccess('UI_ROLE:EDIT')) || (!_vm.roleId && _vm.canAccess('UI_ROLE:CREATE'))) ||
            _vm.role.type === 'ldap' ||
            _vm.isLoggedLdapUser,"rules":[_vm.rules.roleNameRequired, _vm.rules.noSpecialCharactersInName, _vm.rules.roleNameMaxLength],"label":_vm.$t('access.roles.roleDetails.name'),"autofocus":"","max":"ROLE_NAME.MAX_LENGTH"},model:{value:(_vm.role.prettyRoleName),callback:function ($$v) {_vm.$set(_vm.role, "prettyRoleName", $$v)},expression:"role.prettyRoleName"}}):_c('v-text-field',{ref:"roleNameInput",attrs:{"id":"iiotRoleDetailsFormName","disabled":!((_vm.roleId && _vm.canAccess('UI_ROLE:EDIT')) || (!_vm.roleId && _vm.canAccess('UI_ROLE:CREATE'))) ||
            _vm.role.type === 'ldap' ||
            _vm.isLoggedLdapUser,"rules":[_vm.rules.roleNameRequired, _vm.rules.noSpecialCharactersInName, _vm.rules.roleNameMaxLength],"label":_vm.$t('access.roles.roleDetails.name'),"autofocus":"","max":"ROLE_NAME.MAX_LENGTH"},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{ref:"roleDescriptionInput",attrs:{"id":"iiotRoleDetailsFormDescription","disabled":!((_vm.roleId && _vm.canAccess('UI_ROLE:EDIT')) || (!_vm.roleId && _vm.canAccess('UI_ROLE:CREATE'))) ||
            _vm.role.type === 'ldap' ||
            _vm.isLoggedLdapUser,"rules":[_vm.rules.roleDescriptionRequired, _vm.rules.roleDescriptionMaxLength],"label":_vm.$t('access.roles.roleDetails.description'),"max":"ROLE_DESCRIPTION.MAX_LENGTH"},model:{value:(_vm.role.description),callback:function ($$v) {_vm.$set(_vm.role, "description", $$v)},expression:"role.description"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('role-permissions')],1)],1),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"pa-0 pt-4",attrs:{"cols":"12"}},[_c('div',[_c('nerve-button',{ref:"roleDetailsCancelBtn",staticClass:"mr-5 ml-0",attrs:{"id":"iiotRoleDetailsFormCancel","text":_vm.$t('access.roles.roleDetails.cancelBtn'),"type-of-btn":"cancel","size":"normal"},on:{"click-event":function($event){return _vm.goToRoles()}}}),(
                (!_vm.roleId && !_vm.canAccess('UI_ROLE:EDIT')) ||
                (_vm.roleId && _vm.canAccess('UI_ROLE:EDIT') && (_vm.isLoggedLdapUser ? _vm.role.type === 'ldap' : true)) ||
                (!_vm.roleId && _vm.canAccess('UI_ROLE:CREATE'))
              )?_c('nerve-button',{ref:"roleDetailsSaveBtn",attrs:{"id":"iiotRoleDetailsFormSave","text":_vm.roleId === '' ? _vm.$t('access.roles.roleDetails.saveBtn') : _vm.$t('access.roles.roleDetails.updateBtn'),"type-of-btn":"action","size":"normal","type":"submit"}}):_vm._e()],1)])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }