<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->

<template>
  <the-nerve-table
    id="iiotUiPermissionTable"
    ref="uiPermissionTable"
    :columns="uiPermissionTableHeaders"
    :empty-state="$t('access.roles.roleDetails.noUiPermissionsToShow')"
    :is-action-menu-enabled="false"
    :params="fetchParams"
    :disable-pagination="true"
    :is-check-box-column-enabled="true"
    :hide-footer="true"
    :is-autofocus-enabled="false"
    custom-fetch-action="fetch_permissions"
    custom-search-action="search_permissions"
    store-module="roles"
    custom-list-getter="getDisplayedUiPermissions"
    selected-items-getter="getRolePermissions"
    item-key="_id"
    check-item-action="toggle_permission"
    check-all-items-action="toggle_all_ui_permissions"
    :is-row-clickable="canAccess('UI_ROLE:CREATE') && isLoggedLdapUser ? role.type === 'ldap' : true"
  />
</template>

<script>
import { TheNerveTable } from 'nerve-ui-components';

export default {
  components: {
    TheNerveTable,
  },
  data() {
    return {
      isLoggedLdapUser: false,
      fetchParams: {
        searchText: '',
        categories: 'UI_PERMISSION',
      },
    };
  },
  computed: {
    uiPermissionTableHeaders() {
      return [
        {
          text: this.$t('access.roles.roleDetails.uiPermissionHeader'),
          value: 'human_name',
          sortable: false,
        },
        {
          text: this.$t('access.roles.roleDetails.uiDescriptionHeader'),
          value: 'description',
          sortable: false,
          isTruncateEnabled: true,
        },
        {
          text: this.$t('access.roles.roleDetails.uiNameHeader'),
          value: 'name',
          sortable: false,
        },
      ];
    },
    role() {
      return this.$store.getters['roles/getRole'];
    },
  },
  mounted() {
    try {
      if (JSON.parse(localStorage.getItem('userDetails')) && JSON.parse(localStorage.getItem('userDetails')).ldap) {
        this.isLoggedLdapUser = true;
      }
      this.$store.dispatch('roles/fetch_permissions', this.fetchParams);
    } catch (e) {
      this.$log.debug(e);
    }
  },
};
</script>
